<template>
  <v-app>
    <nav>
          <v-app-bar flat color="#ceeaf6">
              <v-app-bar-title>
                  <v-avatar max-height="100" tile>
                      <v-img contain src="@/assets/logo/logo_kedasbeauty.png"></v-img>
                  </v-avatar>
                  <v-btn color="white" class="ml-2" large to="/">
                      <span class="font-weight-bold purple--text text-lowercase">kedasbeautymember.com</span>
                  </v-btn>
              </v-app-bar-title>
          </v-app-bar>
      </nav>
    <v-row no-gutters class="fill-height" style="background-color: #ceeaf6">
      <v-col>
        <v-row no-gutters>
          <v-container>
            <v-carousel
              cycle
              hide-delimiter-background
              :show-arrows="false"
              height="auto"
            >
              <v-carousel-item class="px-0">
                <v-img
                  contain
                  width="100%"
                  src="@/assets/promo/form_01.png"
                  :aspect-ratio="16 / 9"
                ></v-img>
              </v-carousel-item>
            </v-carousel>
          </v-container>
        </v-row>
        <br />
        <v-row no-gutters>
          <v-container>
            <!-- <v-row>
              <v-col class="d-flex justify-center">
                <v-alert
                  dark
                  width="100%"
                  prominent
                  icon="mdi-information"
                  dense
                  color="warning"
                >
                  Event Telah Berakhir.
                </v-alert>
              </v-col>
            </v-row> -->

            <v-card elevation="5" rounded="lg">
              <v-card-title>
                <v-alert prominent dense rounded="lg" color="accent" text>
                  <v-row no-gutters>
                    <v-icon left color="accent">mdi-information-variant</v-icon>
                    Isi Data Diri Untuk Berpartisipasi
                  </v-row>
                </v-alert>
              </v-card-title>
              <v-card-text>
                <v-form ref="promo">
                  <v-text-field
                    outlined
                    label="Nama"
                    v-model="form.data.name"
                    :rules="form.rules.name"
                  ></v-text-field>

                  <v-text-field
                    outlined
                    label="Umur"
                    :rules="form.rules.age"
                    v-model.number="form.data.age"
                  ></v-text-field>
                  <!-- <v-menu
                    ref="birthday"
                    v-model="datepick"
                    :close-on-content-click="false"
                    :return-value.sync="form.data.date_of_birth"
                    min-width="auto"
                    transition="slide-x-transition"
                    offset-y
                  >
                    <template v-slot:activator="{ attrs, on }">
                      <v-text-field
                        label="Tanggal Lahir"
                        v-bind="attrs"
                        v-on="on"
                        v-model="form.data.date_of_birth"
                        readonly
                        outlined
                      ></v-text-field>
                    </template>

                    <v-date-picker no-title v-model="form.data.date_of_birth">
                      <v-spacer></v-spacer>
                      <v-btn
                        icon
                        color="primary"
                        @click="$refs.birthday.save(form.data.date_of_birth)"
                      >
                        <v-icon>mdi-check</v-icon>
                      </v-btn>
                      <v-btn icon @click="datepick = false">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-date-picker>
                  </v-menu> -->

                  <v-text-field
                    outlined
                    label="No. Handphone"
                    v-model="form.data.phone"
                    :rules="form.rules.phone"
                  ></v-text-field>
                  <v-textarea
                    outlined
                    label="Alamat"
                    v-model="form.data.address"
                    :rules="form.rules.address"
                  ></v-textarea>
                  <v-text-field
                    outlined
                    label="Email"
                    v-model="form.data.email"
                    :rules="form.rules.emailRules"
                  ></v-text-field>
                  <v-checkbox
                    label="Saya menyetujui syarat dan ketentuan"
                    v-model="form.data.agree"
                    :rules="form.rules.agree"
                  ></v-checkbox>
                </v-form>
                <v-row no-gutters class="flex-column">
                  <vue-recaptcha
                    ref="recaptcha"
                    :sitekey="secret_checkbox"
                    @verify="onVerify"
                    @expired="onExpired"
                    @error="captchaError"
                  >
                  </vue-recaptcha>
                </v-row>
                <br />
                <v-row no-gutters class="flex-column">
                  <v-btn
                    color="primary"
                    :loading="form.loading"
                    :disabled="form.disable"
                    @click="handleRequest"
                    large
                    depressed
                  >
                    <span>submit</span>
                  </v-btn>
                </v-row>
              </v-card-text>
            </v-card>
            <br />
          </v-container>
        </v-row>
      </v-col>
    </v-row>

    <v-dialog persistent max-width="800" v-model="age_restriction">
      <v-card>
        <v-card-text>
          <br />
          <v-row>
            <v-col class="d-flex justify-center">
              <v-avatar size="64" color="primary">
                <h1 class="white--text">18+</h1>
              </v-avatar>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <h2 class="text-center">
                Anda harus berusia setidaknya 18 tahun untuk melanjutkan
              </h2>
              <br />
              <p class="text-center">
                Dengan melanjutkan, Anda mengonfirmasi bahwa Anda berusia
                setidaknya 18 tahun, dan bahwa Anda telah membaca dan menyetujui
                <strong>Syarat</strong> dan <strong>Ketentuan</strong> kami
              </p>
            </v-col>
          </v-row>
          <v-btn large block color="accent" @click="age_restriction = false">
            <span>Setuju</span>
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-footer class="flex" padless>
      <v-col class="text-center" cols="12">
        {{ new Date().getFullYear() }} —
        <strong>PT. MAHAPURNA MANDIRI UTAMA</strong>
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
import axios from "axios";
import moment from "moment";
import 'moment-timezone'
import { VueRecaptcha } from "vue-recaptcha";
export default {
  data() {
    return {
      age_restriction: true,
      event_id: null,
      prize_id: null,
      deadline: null,
      timezone: null,
      secret_invisible: "6Lcuwh8fAAAAADmhqPJdvCFbNhGJvSwiVmAXu7mY",
      secret_checkbox: "6LdYxRAfAAAAADZg8p5XVxjpGGCXCAxfcFQTkxMv",
      datepick: false,
      isActive: false,
      form: {
        loading: false,
        disable: true,
        data: {
          name: null,
          age: null,
          ip_address: null,
          phone: null,
          address: null,
          email: null,
          agree: false,
        },
        rules: {
          name: [(v) => !!v || "Nama tidak boleh kosong"],
          phone: [(v) => !!v || "No. Handphone tidak boleh kosong"],
          address: [(v) => !!v || "Alamat tidak boleh kosong"],
          age: [(v) => !!v || "Umur tidak boleh kosong"],
          emailRules: [
            (v) =>
              !v ||
              /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
              "E-mail tidak valid",
            (v) => !!v || "E-mail tidak boleh kosong",
          ],
          agree: [(v) => !!v || "Anda harus menyetujui syarat dan ketentuan"],
        },
      },
    };
  },
  methods: {
    async getUserIP() {
      await fetch(process.env.VUE_APP_IP_INFO_URL)
        .then((res) => res.json())
        .then((jsonResponse) => {
          this.form.data.ip_address = jsonResponse.ip;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async onVerify(res) {
      let t = {
        token: res,
      };
      // console.log(t);
      this.form.disable = false;
      await axios
        .post(`${process.env.VUE_APP_KB_API_URL}/api/captcha`, t)
        .then((response) => {
          console.log(response.data);
          if (response.data.success) {
            console.log(response.data);
          }
        });
    },
    async handleRequest() {
      this.form.loading = true;
      this.form.disable = true;
      let isValid = this.$refs.promo.validate();
      this.event_id = this.$route.query.event_id;
      this.prize_id = this.$route.query.prize_id;
      // let age = this.getAge(this.form.data.date_of_birth);

      let newTab = (href) => {
        let a = document.createElement("a");
        a.href = href;
        a.target = "_blank";
        a.click();
      };

      let data = {
        event_id: this.event_id,
        prize_id: this.prize_id,
        ip_address: this.form.data.ip_address,
        name: this.form.data.name,
        age: this.form.data.age,
        phone: this.form.data.phone,
        address: this.form.data.address,
        email: this.form.data.email,
      };

      if (isValid) {
        await axios
          .post(
            `${process.env.VUE_APP_KB_API_URL}/api/participants/${this.event_id}`,
            data
          )
          .then((response) => {
            this.form.disable = false;
            this.form.loading = false;
            // console.log(response.data);
            if (response.data.is_winning == 1) {
              newTab(process.env.VUE_APP_MAIN_GROUP);
              this.$refs.promo.reset();
              this.$router.push({
                name: "WaitingRoom",
                path: "/waiting-room",
                query: {
                  event_id: this.event_id,
                  prize_id: this.prize_id,
                  participant_id: response.data.id,
                  deadline: this.deadline,
                  timezone: this.timezone,
                },
              });
            } else {
              newTab(process.env.VUE_APP_SECOND_GROUP);
              this.$refs.promo.reset();
              this.$router.push({
                name: "WaitingRoom",
                path: "/waiting-room",
                query: {
                  event_id: this.event_id,
                  prize_id: this.prize_id,
                  participant_id: response.data.id,
                  deadline: this.deadline,
                  timezone: this.timezone,
                },
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    getAge(date) {
      let year = moment().diff(date, "years");
      return year;
    },
    onExpired() {
      console.log("expired");
    },
    captchaError(err) {
      console.log(err);
    },
    async getDeadline() {
      await axios
        .get(
          `${process.env.VUE_APP_KB_API_URL}/api/events/${this.$route.query.event_id}`
        )
        .then((response) => {
          this.deadline = response.data.deadline;
          this.timezone = response.data.timezone;
          // console.log(this.deadline, this.timezone);
          // return true
        });
    },
  },
  components: {
    VueRecaptcha,
  },
  mounted() {
    this.getUserIP();
    this.getDeadline();

    /*let deadline = this.deadline
    let timezone = this.timezone

    //get local timezone
    let tz = moment.tz.guess()

    //set timezone
    let time = moment.tz(deadline, timezone)

    //get current local time
    let now = moment().local().format("YYYY-MM-DD HH:mm") */
  },
};
</script>